<template>
	<div class="number-bars">
		<div class="number-bars__title">{{ title }}</div>
		<div class="number-bars__unit">{{ unit }}</div>
		<div class="number-bars__number">
			<counter :number="numbers[0].value" :options="numbers[0].options" />
		</div>
		<div class="number-bars__items" v-viewport>
			<div class="number-bars__item" :key="key" v-for="(number, key) in numbers" :data-value="format(number.value, number.options)">
				<div class="number-bars__item-label">{{ number.label }}</div>
				<div class="number-bars__item-value" :style="{width: barWidth(number, numbers)}" :title="number.value">
					<div class="number-bars__item-value-track"></div>
					<span class="number-bars__item-digit">
						<counter :number="number.value" :options="number.options" />
					</span>
				</div>
			</div>
			<!-- <div class="number-bars__scale">
				<div class="number-bars__scale-item" :key="`scale_${key}`" v-for="(scale, key) in scales(numbers)">
					{{ scale }}
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import Counter from '@/components/Counter'

export default {
	name: 'NumberBars',
	components: {
		Counter,
	},
	props: {
		title: String,
		unit: String,
		numbers: Array,
	},
	mounted() {
		setTimeout(() => {
			this.$el.classList.add('viewed')
		}, 50)
	},
	methods: {
		barWidth(number, numbers) {
			const max = numbers.reduce((a, b) => Math.max(a, b.value), 0)
			return ((number.value || 0) / (max || 1) * 100) + '%'
		},
		scales(numbers) {
			console.log('numbers:', numbers)
			const scales = []
			const dec = (numbers[0].options || {}).decimalPlaces || 0
			const num = numbers.reduce((a, b) => Math.max(a, b.value), 0)
			const base = num / 4
			for (let i = 0; i <= 4; i++) {
				scales.push(((base * i) / 1).toFixed(dec))
				// scales.push(Math.round(num / i / 1) * 1)
			}

			return scales
		},
		format(value, options = {}) {
			return (options.prefix || '') + (new Intl.NumberFormat().format(value))
		},
	},
}
</script>

<style scoped lang="scss">
$color-teal: #00B0AD;
$color-red: #E85F43;
$color-green: #7AC143;
$color-orange: #F78E1E;
$color-yellow: #E3E431;
$light-gray: #EEEEEE;

.number-bars {
	$block: &;
	// align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
	//zoom: 80%;
	@include gutter('margin-left');
	@include gutter('margin-right', 2);
	@include gutter('margin-bottom', 2);

	@include media-query-min-width('tablet') {
		@include gutter('margin-bottom', 0);
		zoom: 100%;
		width: auto;
	}

	// @media screen and (max-height: 780px) and (min-width: 768px) {
	// 	zoom: 90%;
	// }

	@include media-query-min-width('tablet') {
		@include gutter('margin-bottom', 0);
	}

	&--full-width {
		width: 100%;
	}

	&--align-left {
		align-items: flex-start;
		text-align: left;
	}

	.mobile & {
		@include gutter('margin-bottom', 2);
		@include gutter('margin-top', 2);
		@include gutter('margin-left', 0);
		@include gutter('margin-right', 0);
		@include gutter('padding-left', 1);
		@include gutter('padding-right', 1);
		max-width: 100vw;
	}

	&__title {
		@include fontSize(20px);
		color: color_('text');
		font-family: $font-family-secondary;
		font-weight: 900;
		line-height: 0.9;
		position: relative;
		//text-align: center;
		//text-transform: uppercase;
		//white-space: pre;
		z-index: 5;
		display: inline;

		@include media-query-min-width('tablet') {
			@include fontSize(24px);
			display: block;
			//text-align: left;
		}

		.mobile & {
			font-size: 23px;
		}

		#{$block}--align-left & {
			text-align: left;
		}

		#{$block}--on-red &,
		#{$block}--on-green &,
		#{$block}--on-orange &,
		#{$block}--yellow & {
			color: white;
		}
	}

	&__unit {
		@include fontSize(24px);
		color: color_('text', 'light');
		font-family: $font-family-secondary;
		// text-align: center;
		line-height: 1.5;
		display: inline;

		@include media-query-min-width('tablet') {
			text-align: left;
			display: block;
		}

		.mobile & {
			font-size: 12px;
		}

		#{$block}--align-left & {
			text-align: left;
		}

		#{$block}--on-red &,
		#{$block}--on-green &,
		#{$block}--on-orange & {
			color: white;
		}
	}

	&__number {
		@include fontSize(72px);
		color: color_('text', 'xlight');
		font-family: $font-family-secondary;
		font-weight: bold;
		line-height: 1.2;

		@include media-query-min-width('tablet') {
			@include fontSize(120px);
		}

		.mobile & {
			font-size: 60px;
		}

		#{$block}--red & {
			color: $color-red;
		}

		#{$block}--green & {
			color: $color-green;
		}

		#{$block}--orange & {
			color: $color-orange;
		}

		#{$block}--teal & {
			color: $color-teal;
		}

		#{$block}--yellow & {
			color: $color-yellow;
		}
	}

	&__circle {
		background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 100%);		border-radius: 50%;
		display: block;
		flex: 0 0 100%;
		position: absolute;
		bottom: 0;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		z-index: 3;

		&:before {
			content:' ';
			display: block;
			// padding-right: 100%;
			padding-top: 100%;
		}
	}

	&__items {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__item {
		$item: &;
		border-radius: 300px;
		color: color_('primary', 'dark');
		height: 3rem;
		margin-bottom: 0.5rem;
		// overflow: hidden;
		padding: .8em 1.5rem;
		position: relative;
		width: 100%;

		&:first-of-type {
			color: white;

			#{$item}-value {
				#{$item}-value-track {

					#{$block}--red & {
						background-color: $color-red;
						border-color: $color-red;
					}

					#{$block}--green & {
						background-color: $color-green;
						border-color: $color-green;
					}

					#{$block}--orange & {
						background-color: $color-orange;
						border-color: $color-orange;
					}

					#{$block}--teal & {
						background-color: $color-teal;
						border-color: $color-teal;
					}

					#{$block}--yellow & {
						background-color: color_('primary');
						border-color: color_('primary');
					}

					#{$block}--on-red &,
					#{$block}--on-green &,
					#{$block}--on-orange & {
						background-color: white;
					}

					#{$block}--white & {
						background-color: white;
						border-color: white;
					}
				}
			}

			#{$item}-label {
				color: white;

				#{$block}--on-red & {
					color: $color-red;
				}

				#{$block}--on-green & {
					color: $color-green;
				}

				#{$block}--on-orange & {
					color: $color-orange;
				}

				#{$block}--white & {
					color: transparent;
				}
			}
		}


		&-label {
			font-size: 16px;
			//font-weight: 900;
			position: relative;
			z-index: 8;

			#{$block}--white & {
				color: white;
			}

			#{$block}--on-red &,
			#{$block}--on-green &,
			#{$block}--on-orange & {
				color: white;
			}
		}

		&-value {
			align-items: center;
			// background-color: $light-gray;
			// border: 1px solid $light-gray;
			// background-color: rgba(255,255,255,0.5);
			border-radius: 300px;
			display: flex;
			height: 100%;
			justify-content: flex-start;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 7;
			// transform: scaleX(0);
			// transform-origin: left center;
			// transition: transform 3000ms ease-out 500ms;

			&-track {
				background-color: $light-gray;
				border: 1px solid $light-gray;
				border-radius: 300px;
				height: 100%;
				// margin-left: 1rem;
				padding-left: 1rem;
				padding-right: 1rem;
				position: relative;
				transition: width 3000ms ease-out 100ms;
				width: 0;
				// width: calc(100% - 2rem);

				.viewed &,
				.mobile & {
					// transform: scaleX(1);
					width: calc(100% - 2rem);
				}

				#{$block}--on-red &,
				#{$block}--on-green &,
				#{$block}--on-orange & {
					background-color: transparent;
				}

			}

			// #{$block}--white & {
			// 	background-color: transparent;
			// 	border-color: white;
			// }

			// .viewed &,
			// .mobile & {
			// 	transform: scaleX(1);
			// }
		}

		&-digit {
			color: color_('primary', 'dark');
			// position: absolute;
			// right: 0;
			transform: translateX(50%);

			#{$block}--on-red &,
			#{$block}--on-green &,
			#{$block}--on-orange &,
			#{$block}--yellow & {
				color: white;
			}
		}

		&:before,
		&:after {
			pointer-events: none;
			opacity: 0;
			transition: all 150ms $ease-sharp;
		}

		&:before {
			// background-color: #BA4C36;
			color: white;
			// border: 2px solid #BA4C36;
			font-weight: bold;
			font-size: 13px;
			padding: 0.25rem 1rem;
			border-radius: 3px;
			content: attr(data-value);
			display: inline-block;
			position: absolute;
			left: 20%;
			top: 50%;
			transform: translateY(-50%) translateX(50%);
			z-index: 8;

			.mobile & {
				left: 25%;
			}

			// #{$block}--red & {
			// 	background-color: #BA4C36;
			// }

			// #{$block}--green & {
			// 	background-color: #629A36;
			// }

			// #{$block}--teal & {
			// 	background-color: #008D8A;
			// }

			// #{$block}--orange & {
			// 	background-color: #C67218;
			// }
		}

		&:after {
			// background-color: #BA4C36;
			border-left: 4px solid #BA4C36;
			border-bottom: 4px solid #BA4C36;
			border-top: 4px solid transparent;
			border-right: 4px solid transparent;
			content: '' ;
			position: absolute;
			transform: rotate(45deg);
			width: 0px;
			height: 0px;
			left: 20%;
			top: 50%;
			transform: translateY(-50%) translateX(0%) rotate(45deg);
			z-index: 9;

			.mobile & {
				left: 25%;
			}

			#{$block}--red & {
				border-bottom-color: #BA4C36;
				border-left-color: #BA4C36;
			}

			#{$block}--green & {
				border-bottom-color: #629A36;
				border-left-color: #629A36;
			}

			#{$block}--teal & {
				border-bottom-color: #008D8A;
				border-left-color: #008D8A;
			}

			#{$block}--orange & {
				border-bottom-color: #C67218;
				border-left-color: #C67218;
			}

		}


		&:hover,
		.mobile & {
			&:before {
				opacity: 1;
				transform: translateY(-50%) translateX(0%);
			}

			&:after {
				opacity: 1;
				transform: translateY(-50%) translateX(-50%) rotate(45deg);
			}
		}
	}

	&__scale {
		border-top: 2px solid;
		color: color_('text', 'xlight');
		display: flex;
		font-size: 12px;
		justify-content: space-between;
		padding: 0.5rem;
	}
}
</style>
